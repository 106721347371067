import { StyleSheet } from 'react-native';

import { tokens } from '../../tokens';

export const styles = StyleSheet.create({
  fullScreen: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: tokens.colors.$blackOpacity75,
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: tokens.colors.$blackOpacity75,
  },
  drawerContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: tokens.colors.$white,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    paddingTop: 8,
    maxHeight: '90%',
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    minHeight: 56,
  },
  headerTitle: {
    fontSize: 16,
  },
  drawer: {
    flex: 1,
  },
  closeIcon: {
    position: 'absolute',
    right: 26,
  },
  grabber: {
    width: 40,
    height: 4,
    borderRadius: 100,
    backgroundColor: tokens.colors.$black10,
    alignSelf: 'center',
  },
});
