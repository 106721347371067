import { useCallback, useEffect, useRef, useState } from 'react';
import { LayoutChangeEvent, Modal, Animated as RNAnimated } from 'react-native';

import { IconClose, Pressable, Text, XStack, YStack } from '@fhs/ui';
import { useBoxRef } from '@fhs/utils';

import type { ActionSheetProps } from '../action-sheet/types';

import { styles } from './bottom-drawer-styles';

export const BottomDrawer = ({
  isVisible,
  onClose,
  headerTitle,
  children,
  onRequestClose,
  containerStyle,
}: ActionSheetProps) => {
  const [drawerHeight, setDrawerHeight] = useState(0);
  const handleRequestCloseRef = useBoxRef(() => {
    onClose();
  });
  const animation = useRef(new RNAnimated.Value(0));

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setDrawerHeight(height);
  }, []);

  useEffect(() => {
    if (isVisible) {
      RNAnimated.timing(animation.current, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
      return;
    }

    RNAnimated.timing(animation.current, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => handleRequestCloseRef.current?.());
  }, [isVisible, handleRequestCloseRef]);

  const translateY = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [drawerHeight, 0],
  });

  const opacity = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 0.75],
  });

  return (
    <Modal
      visible={isVisible}
      transparent
      style={styles.overlay}
      onRequestClose={onRequestClose ?? handleRequestCloseRef.current}
    >
      <RNAnimated.View style={[styles.fullScreen, { opacity }]}>
        <Pressable
          aria-label="Close dropdown"
          accessible={false}
          focusable={false}
          style={styles.fullScreen}
          onPress={handleRequestCloseRef.current}
        />
      </RNAnimated.View>
      <RNAnimated.View
        style={[styles.drawerContainer, { transform: [{ translateY }] }, containerStyle]}
      >
        {!!headerTitle && (
          <XStack style={styles.header}>
            <Text style={styles.headerTitle} weight="semibold">
              {headerTitle}
            </Text>
            <Pressable style={styles.closeIcon} onPress={onClose}>
              <IconClose size={24} />
            </Pressable>
          </XStack>
        )}
        <YStack style={styles.drawer} onLayout={onLayout}>
          {children}
        </YStack>
      </RNAnimated.View>
    </Modal>
  );
};
