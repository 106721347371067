import { client, useQuery } from '@fhs/client';

import type { MenuQueryOpts } from './types';

export const useMenuPickerQuery = ({
  slug,
  menuQueryOpts: { restaurantId, serviceMode, region },
}: {
  slug: string;
  menuQueryOpts: MenuQueryOpts;
}) =>
  useQuery({
    enabled: Boolean(slug && restaurantId && serviceMode),
    queryKey: ['menus', restaurantId, serviceMode, slug],
    queryFn: async () => {
      if (!(restaurantId && serviceMode && slug)) {
        return;
      }
      const { data: menuItem, errors } = await client.queries.getMenuPickerForRestaurant({
        slug,
        restaurantId,
        serviceMode,
        region,
      });

      if (errors?.length) {
        throw new Error(errors[0].message);
      }
      return menuItem;
    },
  });
